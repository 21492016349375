// Custom.scss

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// $box-shadow:                  0 .5rem 2rem rgba(#000, .2);

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1400px,
//   xxl: 1600px
// );

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1280px,
//   xxl: 1440px
// );


// $spacer: 1rem;
// $spacers: (
//   0: 0,
//   1: $spacer * .25,
//   2: $spacer * .5,
//   3: $spacer * 1,
//   4: $spacer * 1.5,
//   5: $spacer * 2,
//   6: $spacer * 3,
//   7: $spacer * 5,
//   8: $spacer * 6,
//   9: $spacer * 7.5
// );

$theme-colors: (
        "primary":    $o1,
  // "secondary":  $secondary,
  // "success":    $success,
  // "info":       $info,
  // "warning":    $warning,
  // "danger":     $danger,
        "light":      $s150,
        "dark":       rgba(0, 0, 0, 0.5)
);


$card-height: 100%;

$utilities: (
        "opacity": (
                property: opacity,
                values: (
                        0: 0,
                        25: .25,
                        50: .5,
                        75: .75,
                        100: 1,
                )
        ),

        "min-viewport-height": (
                property: min-height,
                class: min-vh,
                values: (
                        100: 100vh,
                        75: 75vh,
                        50: 50vh,
                )
        ),
);


$form-check-input-width:                  1.2em;
$form-check-padding-start:                $form-check-input-width + 1em;

$form-check-input-border:                 1px solid var(--dark);
$form-switch-color:               $o4;
$form-check-input-checked-bg-color:       $o1;
$form-check-input-focus-border:           $o4;
$form-check-input-focus-box-shadow:       $f4;
$form-switch-focus-color:         $o4;



//$form-switch-color:               $o4;
//$form-check-input-checked-bg-color:       $o1;
//$form-check-input-focus-border:           $o4;
//$form-check-input-focus-box-shadow:       $f4;
//$form-switch-focus-color:         $o4;

// $form-text-color:                       currentColor;

// .form-switch .form-check-input {
//   height: 1.2em;
// }

// $form-switch-width:               2em;
// $form-switch-padding-start:       $form-switch-width + .5em;
// $form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
// $form-switch-border-radius:       $form-switch-width;
// $form-switch-transition:          background-position .15s ease-in-out;
//
// $form-switch-focus-color:         $input-focus-border-color;
// $form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");
//
// $form-switch-checked-color:       $component-active-color;
// $form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
// $form-switch-checked-bg-position: right center;



// 3. Include remainder of required Bootstrap stylesheets
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
// @import "../node_modules/bootstrap/scss/root";
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/modal";
// @import "../node_modules/bootstrap/scss/utilities";
// @import "../node_modules/bootstrap/scss/buttons";
// @import "../node_modules/bootstrap/scss/nav";
// @import "../node_modules/bootstrap/scss/navbar";

@import "../../../node_modules/bootstrap/scss/bootstrap";

// 5. Add additional custom code here