.hide.down {
  opacity: 0;
  transform: translateY(100px);
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.hide.up {
  opacity: 0;
  transform: translateY(-100px);
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.show.up {
  opacity: 1;
  transform: translateY(0);
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}


.step__bg {
  opacity: 0;
  @extend .animate__fadeOut;
  animation-duration: 0.5s;
}

.step__bg.active {
  visibility: visible;
  opacity: 1;
  @extend .animate__fadeIn;
}

.step__bg.active + div {
  visibility: hidden;
}

.clock {
  border: 5px solid #FFF;
  border-radius: 100%;
  display: block;
  height: 250px;
  width: 250px;
  position: relative;
}

.clock .start {
  background: #fff;
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 0;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  margin: -105px -2px 0;
  padding: 105px 2px 0;
}

.clock .end {
  background: #fff;
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 0;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  margin: -105px -2px 0;
  padding: 105px 2px 0;
}
