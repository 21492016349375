// BASE TYPOGRAPHY
//-------------------------
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'Formue Headline Medium';
  src: url('../fonts/Formue_Headline_Medium.eot');
  src: url('../fonts/Formue_Headline_Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Formue_Headline_Medium.woff2') format('woff2'),
  url('../fonts/Formue_Headline_Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Berlingske Serif';
    src: url('../fonts/BerlingskeSerif-Rg.eot');
    src: url('../fonts/BerlingskeSerif-Rg.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BerlingskeSerif-Rg.woff2') format('woff2'),
    url('../fonts/BerlingskeSerif-Rg.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Berlingske Serif';
    src: url('../fonts/BerlingskeSerif-DBd.eot');
    src: url('../fonts/BerlingskeSerif-DBd.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BerlingskeSerif-DBd.woff2') format('woff2'),
    url('../fonts/BerlingskeSerif-DBd.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}


// GENERAL
//-------------------------

html, body {
  font-size: 100%;
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
  font-family: 'IBM Plex Sans', sans-serif;
}

body {
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 16px;
  color: $formueTextBlue;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Berlingske Serif', Georgia, serif;
  font-weight: 600;
  word-wrap: normal;
  line-height: 1.2;
}

h1 {
  font-size: 40px;
  line-height: 1.15;

  @include large {
    font-size: 56px;
  }
}

h2 {
  font-size: 56px;

  @include large {
    font-size: 80px;
  }
}

h3 {
  font-size: 32px;
  line-height: 1.3;
  @include large {
    font-size: 40px;
  }
}

h4 {
  font-size: 24px;
  line-height: 1.3;

  // @include large {
  //   font-size: 32px;
  // }
}

h5 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

h6 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

p {
  font-size: 15px;
  line-height: 1.5;
}

p.preamble {
  font-size: 16px;
  line-height: 1.4;
}
