.form {

  max-width: 420px;
  margin: 0 auto;

  .form-text {
    font-size: 13px;
  }

  input:not([type='checkbox']) {
    height: 48px;
    border-radius: 8px;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
  }
}
