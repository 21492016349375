.modal {

  &#infomodal {
    svg {
      position: relative;
      top: 3px;
      @include xlarge {
        top: 6px;
      }
    }
  }
}

.modal-lg {

  @include large {
    max-width: 640px;
  }
}

.modal.fade .modal-dialog {
  transition: transform .6s cubic-bezier(0.23,1,0.32,1);
  transform: translate(0,100%);
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border: none;

  h4 {
    @include large {
      font-size: 28px;
    }
  }
  @include medium {
    border-radius: 24px;
  }
}

.modal-header {
  border-bottom: none;
  padding: 32px;
  text-align: center;

  @include large {
    padding: 32px 32px 0 32px;
  }
}

.modal-body {
  padding: 0 32px 32px 32px;
  // margin-bottom: 32px;
  position: static;

  @include large {
    // margin-bottom: 64px;
    padding: 0 64px 64px 64px;
  }
}

.modal-title {
  opacity: .4;
  position: absolute;
  top: 32px;

  @include large {
    position: relative;
    top: 0;
  }
}

.btn-close {
  background-size: .6em;
  background-color: rgba(0,0,0,.2);
  border-radius: 24px;
}
