$size: 8px;
$width: 70;
$white: rgba(255, 255, 255, .4);
$animation: dotFlashing 1s infinite linear alternate;
.preloader.loading {
  position: fixed;
  z-index: 35;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: 50% 45%;
}

.black-screen {
  background: black;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.dot-flashing {
  position: absolute;
  width: $size;
  height: $size;
  border-radius: $size;
  background-color: $white;
  color: $white;
  animation: $animation;
  animation-delay: .5s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  animation-fill-mode: none;
}

.dot-flashing::before {
  left: -$size*2;
  width: $size;
  height: $size;
  border-radius: $size;
  background-color: $white;
  color: $white;
  animation: $animation;
}

.dot-flashing::after {
  left: $size*2;
  width: $size;
  height: $size;
  border-radius: $size;
  background-color: $white;
  color: $white;
  animation: $animation;
  animation-delay: 1s;
}


@keyframes dotFlashing {
  0% {
    background-color: rgba(255, 255, 255, .4);
  }

  50% {
    background-color: rgba(255, 255, 255, 1);
  }

  100% {
    background-color: rgba(255, 255, 255, .4);
  }
}

.preloader.loaded {
  display: none;
}
