.countdown {
  padding-bottom: 8px;
  display: none;

  .circle {
    height: 16px;
    width: 16px;
    background-color: white;
    border-radius: 16px;
    margin: 0 auto;
  }
}
