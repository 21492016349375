:root {
  --animate-duration: 450ms;
}

.step {
  height: 100%;
  width: 100%;
}
.step__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-size: cover;
  background-position: 50% 65%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
  }
}

.chat {
  padding-top: 16px;
  padding-bottom: 0px;
  display: none;
  height: calc(100% - 4.5rem);
  color: #121212;

  &.loaded {
    display: flex;
  }

  @include large {
    padding-top: 96px;
    padding-bottom: 48px;
  }

  .container {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }
}

.chat-info {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, .5);
  line-height: 1;
  transition: all .15s ease-in-out;
  border: none;


  ///REMOVE IF NOT IN HEADER
  // position: absolute;
  // top: 12px;
  // right: 12px;
  ///REMOVE IF NOT IN HEADER

  &:hover {
    background-color: rgba(0, 0, 0, 1);
    transform: scale(1.1);
  }
}

.chat-annotation {
  display: none;
  font-weight: 500;
  font-size: 14px;
}


.chat-back__btn {
  background-color: rgba(0, 0, 0, .5);
  line-height: 1;
  transition: all .15s ease-in-out;
  border: none;
  height: 36px;
  width: auto;
  font-size: 13px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 16px;
  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }
}

.chat-item {
  max-width: 320px;
  font-weight: normal;
  font-size: 15px;
  width: fit-content;

  @include small {
    max-width: 340px;
    // font-size: 16px;
  }

  &.chat-item--q {
    background-color: #301B59;
    color: #F3EDF7;
    background-color: #C8DEF1;
    color: #121212;
    background-color: #102369;
    color: #B2D0EB;
    border-radius: 16px 16px 16px 2px;
    @extend .animate__animated;
    animation-duration: .4s; //purple in-animation
    animation-delay: .4s;
  }

  &.chat-item--r {
    overflow-x: hidden;
    padding: 16px 24px;
    background-color: $s150;
    border-radius: 16px;
    text-align: center;
    appearance: none;
    border: 0;
    outline: 0;

    &.active {
      font-weight: 500;
      background-color: white!important;
    }
  }

  .chat-item__cat {
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 12px;
    opacity: .4;
    margin-bottom: 8px;
    @include large {
      font-size: 13px;
    }
  }
}

.chat__dialog {
  .chat-item--q {
    padding: 24px 16px 24px 24px;
    @include large {
      padding: 24px;
    }
  }

  .chat-item--r {
    padding: 16px 24px;
    border-radius: 16px 16px 2px 16px;
    text-align: left;
    background-color: #E2D2EB;
    background-color: #C8DEF1;
  }
}

.chat__options {
  .chat-item--r {
    position: relative;
    width: 100%;
    max-width: 340px;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
    color: #121212;

    @include large {
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease 0s;

      &:hover {
        background-color: rgba(255,255,255,.85);
        transform: translateY(-2px);
        box-shadow: rgb(0 0 0 / 5%) 0px 4px 12px 0px;
      }
    }

    &:focus {
      background-color: #eee;
    }
    &:active {
      transform: translateY(-0.5px);
    }
  }

  .chat__options__item {
    @extend .animate__animated;
    &:nth-child(1) {
      animation-delay: 0.7s;
    }

    &:nth-child(2) {
      animation-delay: 0.8s;
    }

    &:nth-child(3) {
      animation-delay: 0.9s;
    }

    &:nth-child(4) {
      animation-delay: 1s;
    }

    &:nth-child(5) {
      animation-delay: 1.1s;
    }

    &:nth-child(6) {
      animation-delay: 1.2s;
    }

    &:nth-child(7) {
      animation-delay: 1.3s;
    }

    &:nth-child(8) {
      animation-delay: 1.4s;
    }
  }
  .chat-item__percent {
    display: none;
  }

  .progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    transition: width 0.5s ease-out;
    background-color: #E2D2EB;
    background-color: #C8DEF1;
  }


  .percent {
    text-align: center;

    .percent-item {
      z-index: 1;
      text-align: center;
      position: relative;
    }
  }


  &.active {

    div[data-irelevant=true] {
      @extend .animate__animated;
      @extend .animate__fadeOutDown;
    }

    button[data-percent="0"] {
      opacity: 0;
      visibility: hidden;
    }

    .chat-back {
      display: none;
    }

    .chat-info {
      display: none;
    }

    .chat-annotation {
      display: block;
    }

    .chat__options__item {
      .chat-item:not(.chat-item--not-relevant) {
        .chat-item__text {
          display: none;
        }

        .chat-item__percent {
          display: block;
          text-align: left;
        }
      }

      .chat-item--not-relevant {
        @extend .animate__animated;
      }
    }
  }

}

.activeScreen.wrapper {
  .chat__dialog, .user-answer, .chat-item.chat-item--q {
    animation-name: fadeOutUp;
    animation-duration: 0.3s; //dialog out animation
    opacity: 0;
  }
}

.activeScreen.wrapper {
  .chat__options {
    animation-name: fadeOutDown;
    animation-duration: 0.3s;
    opacity: 0;
  }
}

.wrapper.inactiveScreen {
  .chat-item--q, .chat__options__item, .user-answer, .chat-item.chat-item--q {
    @extend .animate__fadeInUp;
  }
  .animate__zoomIn {
    animation-name: zoomIn;
  }
}

.active-answer {
  .percent-item {
    font-weight: 600;
  }
}
