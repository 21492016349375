.btn {
  font-weight: 500;

  &:active {
    position: relative;
    top: 1px;
  }
}

.btn-link {
  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  background-color: #385795;
  color: white;
  border: none;
  border-radius: 8px;

  &:hover {
    background-color: #304a7f;
    border-color: #2d4677;
  }
}

.btn-light {
  padding: 12px 40px;
  border-radius: 12px;
  border: none;

  &:hover {
    background-color: #ddd;
  }
}

.answer-button {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent;
  border: none;
}

.active div[data-irelevant="true"] {
  //-webkit-animation-name: fadeOutDown !important;
  //animation-name: fadeOutDown !important;
  //animation-duration: 0.25s;
  //animation-fill-mode: both;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease 0s;
  opacity: 0.6;
}

.button {
  overflow-x: hidden;
  padding: 16px 24px;
  background-color: $lightViolet;
  border-radius: 16px;
  text-align: center;
  appearance: none;
  border: 0;
  outline: 0;
  text-decoration: none;
  font-size: 17px;
  min-width: 280px;
  font-weight: 500;
  display: inline-block;
  color: $darkViolet;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease 0s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 12px 0px;
    color: $darkViolet;
  }
}
