.start header, .results header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

header {
  height: 64px;
  color: white;
  z-index: 1056;
  position: relative;

  .text-counter {
    position: absolute;
    right: 24px;
    font-weight: 500;
    margin-top: -4px;

  }

  .back-outer {
    position: absolute;

    a {
      padding: 10px;
      @extend .animate__animated;
      @extend .animate__fadeInLeft;
      display: block;

      &.inactive {
        display: none;
      }
    }
  }

  .back {
    cursor: pointer;
    transition: opacity .15s ease-in-out;

    &:hover {
      svg {
        opacity: .7;
      }
    }
  }

  .logo-outer {
    margin: 0 auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .15s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }

  .logo {
    height: 20px;
    width: 120px;

    @include large {
      height: 24px;
      width: 140px;
    }
  }
}

.progress {
  height: 5px;
  background-color: rgba(255, 255, 255, .0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  .progress__bar {
    position: absolute;
    background-color: rgba(255, 255, 255, .85);
    width: 0;
    height: 100%;
    transition: width .5s ease-out;
    @extend .animate__animated;
    @extend .animate__slideInLeft;
  }
}
