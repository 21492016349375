.results {
  background-color: #faf9f7;

  .heading {
    @include large {
      height: 140px;
    }
  }
}

.results {
  .banner {
    min-height: 70vh;
    position: relative;
    padding: 64px 0 0px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .2);
    }

    .banner__content {
      z-index: 1;
      position: relative;
    }
  }

  &.no-email {

    .banner {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        background: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
      }
    }
  }
}


hr {
  opacity: 0.05;
  border: 1px solid #000000;
}

.single.false + .single.true {
  margin-top: 48px !important;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: -24px;
    content: '';
    height: 1px;
    background: black;
    opacity: 0.05;
  }
}

.category-result {
  background: $neutral;
  border-radius: 16px;

  p {
    font-size: 15px;
  }

  .category-title {
    font-size: 13px;
    color: $primary;
    opacity: 0.5;
    position: relative;
    top: -4px;
  }


  .single {
    transition: background .05s ease-in-out;
    position: relative;

    &:hover {
      background-color: #F8F8F8;
    }

    &:active {
      top: 1px;
    }


    svg {
      min-width: 16px;
    }

    & + .single {
      margin-top: 12px;
    }


    &.true {
      &.irelevant {
        .category-title {
          text-decoration: line-through;
        }

        .checkbox {
          background: transparent;
          border: 1px solid #182361;
          opacity: 0.3;
        }
      }

      .checkbox {
        background: #C8DEF1;
        border: 1px solid #C8DEF1;
        opacity: 1;
        position: relative;

        &::before {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          content: url('data:image/svg+xml; utf8,<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.87322 6.90661L1.47734 4.50947C1.13892 4.17105 0.592238 4.17105 0.253816 4.50947C-0.0846055 4.84789 -0.0846055 5.39457 0.253816 5.73299L3.2658 8.74622C3.60422 9.08465 4.1509 9.08465 4.48933 8.74622L8.67749 1.81071C9.01591 1.47229 9.01591 0.925612 8.67749 0.58719C8.33906 0.248769 7.79238 0.248769 7.45396 0.58719L3.87322 6.90661Z" fill="%23152365"/></svg>');
          display: block;
          width: 8px;
          height: 25px;
        }
      }

      .category-title + div {
        p {
          display: none;
        }
      }

      .text-start {
        display: flex;
      }

      .category-title {
        font-size: 15px;
        opacity: 1;
        top: 0;
      }

      & > .d-flex {
        align-items: center;
      }
    }

    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
  }
}

.checkbox {
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid $primary;
  opacity: 0.3;
  border-radius: 4px;
  flex: 0 0 18px;
}


h1.score {
  font-size: 144px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  line-height: 0.75;

  @include medium {
    font-size: 240px;
  }

  span {
    font-size: 48px;
    position: absolute;
    right: -40px;
    bottom: 0;
    line-height: 0.75;
    font-weight: 600;

    @include xlarge {
      font-size: 80px;
      right: -64px;
    }
  }
}

// .small {
//   font-size: 15px;
// }

.preamble {
  font-size: 24px;
  line-height: 1.3;
  margin: 0 auto;
}

.block-with-button {
  display: none;
  background: $darkViolet;

  h2 {
    @include xlarge {
      font-size: 40px;
    }
  }

  p {
    color: #E2D2EB;
    opacity: 0.6;
    font-size: 12px;
    line-height: 1.4;
  }
}


#email-modal {
  // h2 {
  //   font-size: 56px;
  //   @include xlarge {
  //     font-size: 72px;
  //   }
  // }

  // p {
  //   font-size: 18px;
  // }

  .modal-content {
    background: transparent;
  }
}

.results-screen.modal-open {
  .modal.fade {
    background: #00000082;
  }

  .modal-backdrop {
    display: none;
  }
}
