//===================================
// Framework
//===================================

* {
  -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow: hidden;

  @include large {
    overflow-x: inherit;
  }
}

body {
  position: relative;
  scroll-behavior: smooth;
  margin: 0 auto;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  height: 100%;
  background-color: black;
}

body {
  visibility: hidden;

  main.quiz {
    display: none;
  }


  &.is-loaded {
    visibility: visible;

    .start {
      display: block;
    }

    main.quiz {
      display: block;
    }
  }
}

.wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 2;

}

.container {
  max-width: 1170px;
}

.start {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.chat .container {
  max-width: 1024px;
}


.bg-blur {
  backdrop-filter: saturate(130%) blur(20px);
}

.text-left {text-align: left}


.background {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2);
  }
}


#dots #dot1{
  animation: load 1s infinite;
}

#dots #dot2{
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#dots #dot3{
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}


.start__content {
  @extend .animate__animated;
  @extend .animate__fadeInUpSmall;
}

.results {
  @extend .animate__animated;
  @extend .animate__fadeIn;

  &:not(.no-email) {
    .banner__content {
      @extend .animate__animated;
      @extend .animate__fadeInUpSmaller;
      animation-delay: .2s;
      animation-duration: 1s;
    }
  }
}

.results.no-email {
  background-color: black;
  .banner {
    @extend .animate__animated;
    @extend .animate__fadeIn;
    animation-duration: 2s;
  }
}

  #email-modal {
  .modal-content {
    @extend .animate__animated;
    @extend .animate__fadeInUpSmaller;
    animation-delay: .2s;
    animation-duration: 1s;
  }
}


.mt-n1 {
  margin-top: -6px;
}
