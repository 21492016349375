.dropdown {
  button {
    appearance: none;
    text-decoration: underline;
    border: none;
    background: none;
    font-size: 13px;
    border-radius: 40px;
    padding: 10px 14px;

    &:hover, &:active, &:focus {
      background-color: rgba(0,0,0,.05);
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(66 70 73 / 20%);
    }
  }
}

.dropdown-menu {
  transition: all ease .25s;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0px, 24px, 0px)!important;
  display: block!important;
  inset: 0px 0px auto auto;
  border-radius: 8px;
  border: none;
  box-shadow: RGB(0 0 0/15%) 0px 0 24px;
  padding: 16px;

  &.show {
    transform: translate3d(0px, 40px, 0px)!important;
    opacity: 1;
    visibility: visible;
  }
}
